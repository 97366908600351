<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1300" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{ showType == 'detail' ? '关闭' : '取消' }}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">
          提交
        </a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules"
                    :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="userdep_id">
          <a-select :disabled="showType=='detail'" v-model="formData.userdep_id" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.userdepid">
              {{ item.monitorpointname }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="查验系统数" prop="inspect_system_num">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.inspect_system_num" type="number"/>
        </a-form-model-item>
        <a-form-model-item label="查验项数" prop="inspect_item_num">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.inspect_item_num" type="number"/>
        </a-form-model-item>
        <a-form-model-item label="合格项数" prop="qualified_num">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.qualified_num" type="number"/>
        </a-form-model-item>
        <a-form-model-item label="不合格项数" prop="unqualified_num">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.unqualified_num" type="number"/>
        </a-form-model-item>
        <a-form-model-item label="合格率" prop="qualification_rate" v-if="showType=='detail'">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.qualification_rate"/>
        </a-form-model-item>
        <a-form-model-item label="完成查验日期" prop="complete_inspection_date">
          <a-date-picker  :disabled="showType==='detail'" v-model.trim="formData.complete_inspection_date" />
        </a-form-model-item>
        <a-form-model-item label="现状接管日期" prop="current_takeover_date">
          <a-date-picker  :disabled="showType==='detail'" v-model.trim="formData.current_takeover_date" />
        </a-form-model-item>
        <a-form-model-item label="单位名称" prop="organization_name">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.organization_name"/>
        </a-form-model-item>
        <a-form-model-item label="档案编号" prop="archive_number">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.archive_number"/>
        </a-form-model-item>
        <a-form-model-item v-if="showType=='detail'" label="是否作废" prop="cancel" >
          <a-select :disabled="showType==='detail'" v-model="formData.cancel">
            <a-select-option value="0">正常</a-select-option>
            <a-select-option value="1">作废</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="承接查验协议" prop="protocol_path">
          <a-upload :disabled="showType==='detail'" name="fileList" :headers="uploadHeaders" :data="uploadData" action="/upload" :file-list="recordList" @change="dataUploaded"
                    accept=".doc,.docx,.pdf,.xls,.xlsx" :beforeUpload="beforeUpload">
            <a-button :disabled="showType==='detail'"> <a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="说明(最多1000个字符)" prop="remark" >
          <a-textarea :disabled="showType==='detail'" v-model.trim="formData.remark" :maxLength="1000"/>
          <div style="position: relative;font-size: 13px;text-align: right;margin-top: -26px;margin-right: 10px;">{{formData.remark.length}}/1000</div>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {mapGetters, mapState} from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {
  addUndertakeInspectionReport, getUndertakeInspectionReportListByCondition
} from "A/businessmanagement";


export default {
  mixins: [areaselect, deptselect, ],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
    ecRecordAllIdAndCodeData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      recordList:[],
      modalBodyStyle: {
        height: '350px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum: '',
        userdep_id: '',
        inspect_system_num:'',
        inspect_item_num:'',
        qualified_num:'',
        unqualified_num:'',
        qualification_rate:'',
        complete_inspection_date:"",
        current_takeover_date:'',
        organization_name:"",
        archive_number:'',
        cancel:"0",
        remark:'',
        protocol_path:''
      },
      //这里面的数据属于必填项
      formRules: {
        userdep_id: [{required: true, message: '请选择项目'}],
        inspect_system_num: [{required: true, message: '请输入查验系统数'}],
        inspect_item_num: [{required: true, message: '请输入查验项数'}],
        qualified_num: [{required: true, message: '请输入合格项数'}],
        unqualified_num: [{required: true, message: '请输入不合格项数'}],
        //qualification_rate: [{required: true, message: '请输入合格率'}],
        complete_inspection_date: [{required: true, message: '请输入完成查验日期'}],
        current_takeover_date: [{required: true, message: '请输入现状接管日期'}],
        organization_name: [{required: true, message: '请输入单位名称'}],
        archive_number: [{required: true, message: '请输入档案编号'}],
        cancel: [{required: true, message: ''}],
        //remark: [{required: true, message: '请输入说明'}],
      },
      ecPositionAllList: [],
      monitorpointList: [],
      ecCategoryAllList:[],

      ecMeterFormAllList: [],
      ecGradeList: [],
    }
  },
  computed: {
    ...mapGetters(['uploadHeaders', 'uploadData']),
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if (this.showType == 'add') {
        return '新增'
      } else if (this.showType == 'edit') {
        return '修改'
      } else if (this.showType == 'detail') {
        return '详情'
      } else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if (!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
    'formData.userdep_id'(val) {
      //this.formData.monitorpointnum = ''
      for (let item of this.monitorpointList) {
        if (item.userdepid === val) {
          this.formData.monitorpointnum = item.monitorpointnum
        }
      }
      if(val && (val+'').length>0 && this.showType==='add'){
        this.getTableData(val)
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    onChange(option) {
      this.formData.host_num = option.replace(/\s*/g, "")
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getTableData(val) {
      let params = {
        userdepsid:val,
        pageno: 1,
        pagesize: 10,
        void:'0'
      };
      getUndertakeInspectionReportListByCondition(params).then(res => {
        if (res && res.returncode === '0') {
          if(res.count && res.count>0){
            this.$message.error('此项目已经存在承接查验报告，如果要新建，请先作废原有报告！！！')
            this.formData.userdep_id=''
          }
        }
      })
    },
    getMonitorPointNameList() {
      getMonitorPointNameListByCondition({userdepsid: this.userInfo.userdepid}).then(res => {
        if (res && res.returncode == '0') {
          this.monitorpointList = res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.formData.userdep_id = ''
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.id) {
        if (this.showType == 'edit' || this.showType == 'detail') {
          this.formData.id = this.detailData.id
          this.formData.inspect_system_num = this.detailData.inspect_system_num
          this.formData.inspect_item_num = this.detailData.inspect_item_num
          this.formData.qualified_num = this.detailData.qualified_num
          this.formData.unqualified_num = this.detailData.unqualified_num
          this.formData.qualification_rate = this.detailData.qualification_rate_+'%'
          this.formData.complete_inspection_date = this.detailData.complete_inspection_date
          this.formData.current_takeover_date = this.detailData.current_takeover_date
          this.formData.organization_name = this.detailData.organization_name
          this.formData.archive_number = this.detailData.archive_number
          this.formData.cancel = this.detailData.void+''
          this.formData.remark = this.detailData.remark
          this.formData.protocol_path = this.detailData.protocol_path

          const file_name = this.detailData.protocol_path.split("/").pop();
          this.recordList = [{
            uid: 'filenameuid',
            name: file_name,
            status: 'done',
            url: this.detailData.protocol_path,
          }];

          this.formData.userdep_id = this.detailData.userdep_id
          this.formData.monitorpointnum = this.detailData.monitorpointnum

        } else {
          this.resetData()
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            if(this.formData.inspect_item_num==='0'){
              this.$message.error('查验项数不能等于0');
              return
            }else if(Number(this.formData.inspect_item_num)<Number(this.formData.qualified_num)||Number(this.formData.inspect_item_num)<Number(this.formData.unqualified_num)){
              this.$message.error('查验项数不能小于合格项数或者不合格项数');
              return
            }
            this.formData.complete_inspection_date=moment(this.formData.complete_inspection_date).format("YYYY-MM-DD")
            this.formData.current_takeover_date=moment(this.formData.current_takeover_date).format("YYYY-MM-DD")
            let params = {
              ...this.formData,
              //userdepsid:this.formData.userdep_id
            };
            if (this.showType == 'add') {
              this.showLoading();
              params.cancel='0'
              addUndertakeInspectionReport(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if (this.showType == 'edit') {
              this.showLoading();

              this.hideLoading();
            } else {
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    dataUploaded(info){
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      //console.log(fileList)
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.formData.protocol_path = file.response.urlPath;
        }
        return file;
      });
      this.recordList = fileList;
    },

    beforeUpload(file){
      return new Promise((resolve, reject) => {
        const isJpgOrPng =
            file.type === "application/pdf" ||
            file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            file.type === "application/msword"||
            file.type === "application/vnd.ms-excel"||
            file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (!isJpgOrPng) {
          this.$message.error("上传文件格式只能是doc/docx/pdf/xls/xlsx");
          return reject(false);
        }
        const isLimit = file.size / 1024 / 1024 < 5;
        if (!isLimit) {
          this.$message.error("上传文件大小不能超过 5MB!");
          return reject(false);
        }
        return resolve(true);
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>